import { QueryCache, QueryClient } from "@tanstack/react-query";
import { FetchError } from "./customFetch";
import { baseUrl } from "./config/services";

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof FetchError && error.status === 401) {
        window.location.href = `${baseUrl}/api/auth/login?returnTo=${window.location.pathname}`;
      }
    },
  }),
  defaultOptions: {
    queries: { retry: 1, staleTime: 0, gcTime: 60 * 1000 },
    mutations: { retry: 0 },
  },
});
