import toast from "react-hot-toast";
import { queryClient } from "./queryClient";
import { MeResponse } from "./types/response/me";

export async function customFetch(url: string, params?: RequestInit) {
  const me = queryClient.getQueryData<MeResponse>(["me"]);
  const headers = {
    "Content-Type": "application/json",
  } as Record<string, string>;
  if (me?.accessToken) headers["Authorization"] = `Bearer ${me.accessToken}`;
  const res = await fetch(url, {
    headers,
    ...params,
  });
  const isResponseJSON = res.headers
    .get("Content-Type")
    ?.includes("application/json");
  if (!res.ok) {
    let message = "Unknown error";
    if (isResponseJSON) {
      const response = await res.json();
      if (typeof response.title === "string" && response.title.length)
        message = response.title;
    }
    if (!res.url.includes("/api/auth/me") && me?.accessToken) {
      toast.error(message, { id: url });
    }
    throw new FetchError(message, res.status);
  }
  if (isResponseJSON) return res.json();
  return res.text();
}

export class FetchError extends Error {
  public status: number;
  constructor(message = "Unknown error", status: number) {
    super(message);
    this.status = status;
  }
}
