"use client";

import { useAppTheme } from "@/utils/hooks/useAppTheme";
import { ConfigProvider, Layout, theme } from "antd";
import React, { PropsWithChildren, useEffect } from "react";

function ThemeWrapper({ children }: PropsWithChildren) {
  const { theme: appTheme, sync: syncTheme } = useAppTheme();

  useEffect(() => {
    // This will avoid hydration mismatches
    // but also creates glitch effect until this effect gets chance to run
    syncTheme();
  }, [syncTheme]);

  return (
    <ConfigProvider
      theme={{
        algorithm:
          appTheme === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        components: {
          Layout: {
            siderBg: appTheme === "dark" ? "#141414" : "#001529",
            triggerBg: appTheme === "dark" ? "#1D1D1D" : "#002140",
          },
        },
      }}
    >
      <Layout>{children}</Layout>
    </ConfigProvider>
  );
}

export default ThemeWrapper;
