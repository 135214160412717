import { create } from "zustand";

interface ThemeState {
  theme: "light" | "dark";
  toggle: () => void;
  sync: () => void;
}

const defaultTheme = "light";

export const useAppTheme = create<ThemeState>((set, get) => ({
  theme: defaultTheme,
  sync: () => set({ theme: getInitialTheme() }),
  toggle: () => {
    const currentTheme = get().theme;
    if (currentTheme === "dark") {
      localStorage.setItem("theme", "light");
      set({ theme: "light" });
    } else {
      localStorage.setItem("theme", "dark");
      set({ theme: "dark" });
    }
  },
}));

function getPreferredColorScheme() {
  if (!window.matchMedia) {
    return "light";
  }
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark";
  } else {
    return "light";
  }
}

function getInitialTheme() {
  const theme = localStorage.getItem("theme") ?? getPreferredColorScheme();
  if (["light", "dark"].includes(theme)) return theme as "light" | "dark";
  return defaultTheme;
}
