export const aggerApiUrl = process.env.NEXT_PUBLIC_AGGER_API_URL;
export const IS_LOCAL = process.env.NEXT_PUBLIC_ENV === "local";
export const baseUrl =
  process.env.NEXT_PUBLIC_BASE_URL || "http://localhost:3000";

export const cookieOptions = {
  httpOnly: false,
};

export const servicesList = {
  companies: "companies",
  users: "users",
  events: "events",
  installers: "installers",
  roles: "roles",
  licenses: "licenses",
  auth: "auth",
  incidents: "incidents",
  stats: "stats",
  permissions: "permissions",
  endpoints: "endpoints",
  globalConfigs: "global-configs",
  endpointConfigs: "endpoint-configs",
  groupConfigs: "group-configs",
  hashes: "hashes",
  apiClients: "api-clients",
  testReports: "test-reports",
  resources: "resources",
};

export const services = new Proxy(servicesList, {
  get: function (target, name: keyof typeof servicesList) {
    return `${aggerApiUrl}/v1/${target[name]}`;
  },
});
